import classNames from 'classnames';
import * as React from 'react';
import { MenuButtonProps } from '../../../MenuButton.types';
import MenuButtonBackground from '../../MenuButtonBackground';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonRoot from '../../MenuButtonRoot';
import skinsStyle from './styles/skins.scss';

const skin = 'SeparateBasicMenuButtonNSkin';

const SeparateBasicMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  const {
    id,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
    className,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(className, skinsStyle[skin])}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton && ariaHasPopup,
            isMoreButton,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <div className={skinsStyle.wrapper}>
            <div className={skinsStyle.borderWrapper}>
              <MenuButtonBackground
                wrapperProps={{ dir, textAlign, id }}
                classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
              >
                {label}
              </MenuButtonBackground>
            </div>
          </div>
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default SeparateBasicMenuButtonNSkin;
